$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 30px;
$font-size-9: 36px;
$font-size-10: 48px;
$font-size-11: 60px;
$font-size-12: 72px;

$gap-1: 4px;
$gap-2: 8px;
$gap-3: 12px;
$gap-4: 16px;
$gap-5: 24px;
$gap-6: 32px;
$gap-7: 48px;
$gap-8: 64px;
$gap-9: 96px;
$gap-10: 128px;
$gap-11: 192px;
$gap-12: 256px;
$gap-13: 384px;
$gap-14: 512px;
$gap-15: 640px;
$gap-16: 768px;

$small-border-radius: 4px;
$border-radius: 4px;
$large-border-radius: 4px;

$compact-sidebar-width: 50px;
$extended-sidebar-width: 150px;
$nav-height: 48px;

$dirty-white: #f5f7fa;
$background-gray: #d2d6dc;
$border-color: #cad1d8;
$text-black: #484848;
$input-black: #585858;
$kindred-1: #e84393;
$kindred-2: #fd79a8;

$primary: #10ac84;
$error-red: #eb4d4b;
$hyperlink-color: #5758BB;

$font: Muli;
$logo-font: Catamaran;
