.signup-form {
    background-color: $dirty-white;
    padding: $gap-5 $gap-4;
    border-radius: $border-radius;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 350px;
    margin: 0 auto;

    * {
        transition-property: opacity;
        transition-duration: 0.3s;
    }

    .general-error {
        text-align: center;
    }

    .input {
        margin-bottom: $gap-4;

        label {
            font-size: $font-size-2;
        }

        .error {
            font-size: $font-size-1;
        }

        input {
            display: block;
            background-color: white;
            width: 100%;
            padding: $gap-2 $gap-3;
            border: 1px solid $border-color;
            border-radius: $small-border-radius;
        }
    }

    input[type=submit] {
        background-color: $primary;
        color: white;
        font-size: $font-size-2;
        padding: $gap-2 $gap-2;
        border-radius: $small-border-radius;
        margin-top: $gap-4;
        font-weight: 800;
    }

    .links {
        display: grid;
        margin: 0 auto;
        margin-top: $gap-5;
        gap: $gap-3;
        

        a {
            text-align: center;
        }
    }

    @media (min-width: 300px) {
        padding: $gap-6 $gap-5;

        .input {
            margin-bottom: $gap-5;
    
            label {
                display: block;
                font-size: $font-size-3;
            }
    
            input {
                margin-top: $gap-1;
                padding: $gap-2 $gap-3;
                font-size: $font-size-4;
            }
        }
    
        input[type=submit] {
            font-size: $font-size-3;
            padding: $gap-2 $gap-2;
            margin-top: $gap-5;
        }

        .links {
            grid-template-columns: repeat(2, 110px);
            gap: 0;
            margin-top: $gap-6;
        }
    }
}

.signup-form.loading {
    * {
        opacity: 0;
    }

    div {
        opacity: 1;
    }

    div.error {
        opacity: 0;
    }
}